export enum Roles {
    Empty = '',
    Superadmin = 1,
    Trainer = 2,
    Athlete = 3
}

export type Role = {
    id: number;
    role: Roles;
}
