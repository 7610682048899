import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../../api/api.service";
import {Programazione} from "../../types/Programmazione";
import {DettaglioProgrammazione, ParsedProgrammazioneWeeks, RepScheme, SplitsExercise, WeekScheme, WeekSetScheme} from "../../types/DettaglioProgrammazione";

export interface EditRepSchemeModal {
    modalOpen: boolean,
    exerciseSplit: number | null,
    exerciseIndex: number | null,
    repSchemeSet: number | null,
    repSchemeItem: RepScheme | null,
    tecnica: string | null,
}

export interface ExtendedRepSchemeModal extends EditRepSchemeModal {
    isXRepsIncluded: boolean | false;
    isSuggestedWeight: boolean | false;
}

export interface EditWeekModal {
    modalOpen: boolean | null,
    weekNumber: number | null,
    weekSet: number | null,
    weekItem: WeekSetScheme | null,
    exerciseSplit: number | null,
    exerciseIndex: number | null
}

export interface ExtendedWeekModal extends EditWeekModal {
    isXRepsIncluded: boolean | false;
}

export interface ExerciseDetailModal {
    exercise: SplitsExercise | null;
    userId: number | null;
    parentId: number | null;
}

export interface ProgrammazioneWeeksModal {
    weeks: Array<{
        splitNumber: number;
        exerciseIndex: number;
        weeks: Array<WeekScheme>;
    }>
}

export type ProgrammazioneState = {
    list: Array<Programazione>,
    selectedProgrammazione?: Programazione | undefined,
    selectedDettaglio?: DettaglioProgrammazione | undefined,
    selectedWeekDettaglio?: ParsedProgrammazioneWeeks | undefined,
    newItem?: DettaglioProgrammazione,
    totalPages?: number | undefined,
    editRepSchemeModal: ExtendedRepSchemeModal,
    editWeekSchemeModal: ExtendedWeekModal,
    selectedExerciseModal: ExerciseDetailModal,
    selectedSplitNumber: number | undefined,
    selectedExerciseIndex: number | undefined,
    selectedWeeksModal: ProgrammazioneWeeksModal | [],
    selectedUrlModal: string;
    imagePathData: string;
    copiedExercise?: SplitsExercise | SplitsExercise[] | undefined;
    copiedRepScheme?: {
        repSchemeType: string;
        sets: Array<RepScheme>;
        tecnica?: string;
        nRm?: number;
        pesoN?: string,
        suggestedPercentage?: string;
        sogliaPeso?: string;
    };
    copiedRepSchemeSet?: RepScheme;
    copiedWeek?: WeekSetScheme[];
    copiedWeekSet?: WeekSetScheme;
    copiedProgrammazione?: Programazione;
    copiedSplit?: {
        splitName: string | '';
        exercises: SplitsExercise[];
    };
    copiedWeekReps?: string;
    copiedWeekEffReps?: string;
    copiedWeekEffXReps?: string;
    copiedWeekKg?: string;
    copiedWeekTUT?: string;
    copiedWeekEffort?: string;
    copiedWeekRec?: string;
    copiedWeekTecnica?: string;
};

const initialState: ProgrammazioneState = {
    copiedExercise: undefined,
    list: [],
    newItem: { splits: [] },
    selectedProgrammazione: undefined,
    selectedDettaglio: undefined,
    selectedWeekDettaglio: undefined,
    totalPages: undefined,
    editRepSchemeModal: {
        modalOpen: false,
        exerciseIndex: null,
        exerciseSplit: null,
        repSchemeSet: null,
        repSchemeItem: null,
        tecnica: null,
        isXRepsIncluded: false,
        isSuggestedWeight: false
    },
    editWeekSchemeModal: {
        modalOpen: false,
        weekNumber: null,
        weekSet: null,
        weekItem: null,
        exerciseSplit: null,
        exerciseIndex: null,
        isXRepsIncluded: false
    },
    selectedExerciseModal: {
        exercise: null,
        userId: null,
        parentId: null,
    },
    selectedWeeksModal: [],
    selectedSplitNumber: undefined,
    selectedExerciseIndex: undefined,
    copiedRepScheme: undefined,
    selectedUrlModal: '',
    imagePathData: '',
    copiedRepSchemeSet: undefined,
    copiedWeek: undefined,
    copiedWeekSet: undefined,
    copiedProgrammazione: undefined,
    copiedSplit: undefined,
    copiedWeekReps: undefined,
    copiedWeekEffReps: undefined,
    copiedWeekKg: undefined,
    copiedWeekTUT: undefined,
    copiedWeekEffort: undefined,
    copiedWeekRec: undefined,
    copiedWeekTecnica: undefined
};

export const programmazioniSlice = createSlice({
    name: 'programmazioni',
    initialState,
    reducers: {
        resetProgrammazioneList: () => initialState,
        setProgrammazioneList: (state, action: PayloadAction<Programazione[]>) => {
            state.list = action.payload;
        },
        setProgrammazioneNewItem: (state, action: PayloadAction<DettaglioProgrammazione>) => {
            state.newItem = action.payload;
        },
        setProgrammazioneWeeks: (state, action: PayloadAction<ProgrammazioneWeeksModal>) => {
            state.selectedWeeksModal = action.payload;
        },
        resetProgrammazioneWeeks: (state) => {
            state.selectedWeeksModal = initialState.selectedWeeksModal;
        },
        resetProgrammazioneNewItem: (state) => {
            state.newItem = initialState.newItem;
        },
        setEditRepSchemeModal: (state, action: PayloadAction<ExtendedRepSchemeModal>) => {
            state.editRepSchemeModal = action.payload;
        },
        resetEditRepSchemeModal: (state) => {
            state.editRepSchemeModal = initialState.editRepSchemeModal;
        },
        setEditWeekchemeModal: (state, action: PayloadAction<ExtendedWeekModal>) => {
            state.editWeekSchemeModal = action.payload;
        },
        resetEditWeekSchemeModal: (state) => {
            state.editWeekSchemeModal = initialState.editWeekSchemeModal;
        },
        setSelectedProgrammazione: (state, action: PayloadAction<Programazione>) => {
            state.selectedProgrammazione = action.payload;
        },
        resetSelectedProgrammazione: (state) => {
            state.selectedProgrammazione = initialState.selectedProgrammazione;
        },
        setSelectedDettaglio: (state, action: PayloadAction<DettaglioProgrammazione>) => {
            state.selectedDettaglio = action.payload;
        },
        resetSelectedDettaglio: (state) => {
            state.selectedDettaglio = initialState.selectedDettaglio;
        },
        setSelectedWeekDettaglio: (state, action: PayloadAction<ParsedProgrammazioneWeeks>) => {
            state.selectedWeekDettaglio = action.payload;
        },
        resetSelectedWeekDettaglio: (state) => {
            state.selectedWeekDettaglio = initialState.selectedWeekDettaglio;
        },
        setExerciseDetailModal: (state, action: PayloadAction<ExerciseDetailModal>) => {
            state.selectedExerciseModal = action.payload;
        },
        setSelectedSplitNumber: (state, action: PayloadAction<number>) => {
            state.selectedSplitNumber = action.payload;
        },
        resetSelectedSplitNumber: (state) => {
            state.selectedSplitNumber = initialState.selectedSplitNumber;
        },
        setSelectedExerciseIndex: (state, action: PayloadAction<number>) => {
            state.selectedExerciseIndex = action.payload;
        },
        resetSelectedExerciseIndex: (state) => {
            state.selectedExerciseIndex = initialState.selectedExerciseIndex;
        },
        resetExerciseDetailModal: (state) => {
            state.selectedExerciseModal = initialState.selectedExerciseModal;
        },
        setSelectedUrlModal: (state, action) => {
            state.selectedUrlModal = action.payload;
        },
        resetSelectedUrlModal: (state) => {
            state.selectedUrlModal = initialState.selectedUrlModal;
        },
        setSelectedImagePathData: (state, action) => {
            state.imagePathData = action.payload;
        },
        resetSelectedImagePathData: (state) => {
            state.imagePathData = initialState.imagePathData;
        },
        setCopiedExercise: (state, action) => {
            state.copiedExercise = action.payload;
        },
        resetCopiedExercise: (state) => {
            state.copiedExercise = initialState.copiedExercise;
        },
        setCopiedRepScheme: (state, action) => {
            state.copiedRepScheme = action.payload;
        },
        resetCopiedRepScheme: (state) => {
            state.copiedRepScheme = initialState.copiedRepScheme;
        },
        setCopiedRepSchemeSet: (state, action) => {
            state.copiedRepSchemeSet = action.payload;
        },
        resetCopiedRepSchemeSet: (state) => {
            state.copiedRepSchemeSet = initialState.copiedRepSchemeSet;
        },
        setCopiedWeek: (state, action) => {
            state.copiedWeek = action.payload;
        },
        resetCopiedWeek: (state) => {
            state.copiedWeek = initialState.copiedWeek;
        },
        setCopiedWeekSet: (state, action) => {
            state.copiedWeekSet = action.payload;
        },
        resetCopiedWeekSet: (state) => {
            state.copiedWeekSet = initialState.copiedWeekSet;
        },
        setCopiedProgrammazione: (state, action) => {
            state.copiedProgrammazione = action.payload;
        },
        resetCopiedProgrammazione: (state) => {
            state.copiedProgrammazione = initialState.copiedProgrammazione;
        },
        setCopiedSplit: (state, action) => {
            state.copiedSplit = action.payload;
        },
        resetCopiedSplit: (state) => {
            state.copiedSplit = initialState.copiedSplit;
        },
        setCopiedWeekReps: (state, action) => {
            state.copiedWeekReps = action.payload;
        },
        resetCopiedWeekReps: (state) => {
            state.copiedWeekReps = initialState.copiedWeekReps;
        },
        setCopiedWeekEffReps: (state, action) => {
            state.copiedWeekEffReps = action.payload;
        },
        resetCopiedWeekEffReps: (state) => {
            state.copiedWeekEffReps = initialState.copiedWeekEffReps;
        },
        setCopiedWeekEffXReps: (state, action) => {
            state.copiedWeekEffXReps = action.payload;
        },
        resetCopiedWeekEffXReps: (state) => {
            state.copiedWeekEffXReps = initialState.copiedWeekEffXReps;
        },
        setCopiedWeekKg: (state, action) => {
            state.copiedWeekKg = action.payload;
        },
        resetCopiedWeekKg: (state) => {
            state.copiedWeekKg = initialState.copiedWeekKg;
        },
        setCopiedWeekTUT: (state, action) => {
            state.copiedWeekTUT = action.payload;
        },
        resetCopiedWeekTUT: (state) => {
            state.copiedWeekTUT = initialState.copiedWeekTUT;
        },
        setCopiedWeekEffort: (state, action) => {
            state.copiedWeekEffort = action.payload;
        },
        resetCopiedWeekEffort: (state) => {
            state.copiedWeekEffort = initialState.copiedWeekEffort;
        },
        setCopiedWeekRec: (state, action) => {
            state.copiedWeekRec = action.payload;
        },
        resetCopiedWeekRec: (state) => {
            state.copiedWeekRec = initialState.copiedWeekRec;
        },
        setCopiedWeekTecnica: (state, action) => {
            state.copiedWeekTecnica = action.payload;
        },
        resetCopiedWeekTecnica: (state) => {
            state.copiedWeekTecnica = initialState.copiedWeekTecnica;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(api.endpoints.getProgrammazoniList.matchPending, (state, action) => {
            console.info('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getProgrammazoniList.matchFulfilled, (state, action) => {
            console.info('Fulfilled: ', state, action);
            state.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.getProgrammazione.matchPending, (state, action) => {
            console.info('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getProgrammazione.matchFulfilled, (state, action) => {
            console.info('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.getSingleProgramazioneWeeks.matchPending, (state, action) => {
            console.info('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getSingleProgramazioneWeeks.matchFulfilled, (state, action) => {
            console.info('Fulfilled: ', state, action);
        });
    }
});

export const {
    setCopiedExercise,
    resetProgrammazioneList,
    setProgrammazioneList,
    setProgrammazioneNewItem,
    resetProgrammazioneNewItem,
    setEditRepSchemeModal,
    resetEditRepSchemeModal,
    setEditWeekchemeModal,
    resetEditWeekSchemeModal,
    setSelectedProgrammazione,
    resetSelectedProgrammazione,
    setSelectedDettaglio,
    resetSelectedDettaglio,
    setSelectedWeekDettaglio,
    resetSelectedWeekDettaglio,
    setExerciseDetailModal,
    resetExerciseDetailModal,
    setSelectedUrlModal,
    resetSelectedUrlModal,
    setSelectedImagePathData,
    resetSelectedImagePathData,
    setProgrammazioneWeeks,
    resetProgrammazioneWeeks,
    setSelectedSplitNumber,
    setSelectedExerciseIndex,
    resetSelectedSplitNumber,
    resetSelectedExerciseIndex,
    setCopiedRepScheme,
    resetCopiedRepScheme,
    setCopiedRepSchemeSet,
    resetCopiedRepSchemeSet,
    setCopiedWeek,
    resetCopiedWeek,
    setCopiedWeekSet,
    resetCopiedWeekSet,
    setCopiedProgrammazione,
    resetCopiedProgrammazione,
    setCopiedSplit,
    resetCopiedSplit,
    setCopiedWeekReps,
    resetCopiedWeekReps,
    setCopiedWeekEffReps,
    resetCopiedWeekEffReps,
    setCopiedWeekEffXReps,
    resetCopiedWeekEffXReps,
    setCopiedWeekKg,
    resetCopiedWeekKg,
    setCopiedWeekTUT,
    resetCopiedWeekTUT,
    setCopiedWeekEffort,
    resetCopiedWeekEffort,
    setCopiedWeekRec,
    resetCopiedWeekRec,
    setCopiedWeekTecnica,
    resetCopiedWeekTecnica
} = programmazioniSlice.actions;

export default programmazioniSlice.reducer;
