import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type ChatState = {
    unreadMessages: number;
}

const initialState: ChatState = {
    unreadMessages: 0
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setUnreadMessages: (state, action: PayloadAction<number>) => {
            state.unreadMessages = action.payload;
        },
        resetUnreadMessages: (state) => {
            state.unreadMessages = initialState.unreadMessages;
        }
    }
});

export const {
    setUnreadMessages,
    resetUnreadMessages
} = chatSlice.actions;

export default chatSlice.reducer;