import {createBrowserRouter, Navigate, Outlet,} from "react-router-dom";
import {useAuth} from "../Providers/AuthProvider";
import {lazy} from "react";
import {Roles} from "../enums/Roles";

const Root = lazy(() => import('../Layout/Root'));
const Login = lazy(() => import('../Views/Login'));
const ViewDashboard = lazy(() => import('../Views/ViewDashboard'));
const MyUserData = lazy(() => import('../Views/MyUserData/index'));
const ViewNotFound = lazy(() => import('../Views/ViewNotFound/index'));
const ViewUsersList = lazy(() => import('../Views/ViewUsersList'));
const ViewUserData = lazy(() => import('../Views/ViewUserData/index'));
const ViewMotorPatternList = lazy(() => import('../Views/ViewMotorPatternList/index'));
const AddNewMotorPattern = lazy(() => import('../Views/ViewMotorPatternList/partials/AddNewMotorPattern'));
const EditMotorPattern = lazy(() => import('../Views/ViewMotorPatternList/partials/EditMotorPattern'));
const ViewMuscleGroupList = lazy(() => import('../Views/ViewMuscleGroupList/index'));
const AddNewMuscleGroup = lazy(() => import('../Views/ViewMuscleGroupList/partials/AddNewMuscleGroup'));
const EditMuscleGroup = lazy(() => import('../Views/ViewMuscleGroupList/partials/EditMuscleGroup'));
const ViewExerciseList = lazy(() => import('../Views/ViewExerciseList/index'));
const AddNewExercise = lazy(() => import('../Views/ViewExerciseList/partials/AddNewExercise'));
const EditExercise = lazy(() => import('../Views/ViewExerciseList/partials/EditExercise'));
const ViewVariantList = lazy(() => import('../Views/ViewVariantList/index'));
const AddNewVariant = lazy(() => import('../Views/ViewVariantList/partials/AddNewVariant'));
const EditVariant = lazy(() => import('../Views/ViewVariantList/partials/EditVariant'));
const ViewTecnicaList = lazy(() => import('../Views/ViewTecnicaList/index'));
const AddNewTecnica = lazy(() => import('../Views/ViewTecnicaList/partials/AddNewTecnica'));
const EditTecnica = lazy(() => import('../Views/ViewTecnicaList/partials/EditTecnica'));
const ViewTUTList = lazy(() => import('../Views/ViewTUTList/index'));
const AddNewTUT = lazy(() => import('../Views/ViewTUTList/partials/AddNewTUT'));
const EditTUT = lazy(() => import('../Views/ViewTUTList/partials/EditTUT'));
const ViewRecList = lazy(() => import('../Views/ViewRecList/index'));
const AddNewRec = lazy(() => import('../Views/ViewRecList/partials/AddNewRec'));
const EditRec = lazy(() => import('../Views/ViewRecList/partials/EditRec'));
const ViewProgrammazioniList = lazy(() => import('../Views/ViewProgrammazioniList'));
const ProgrammazioneDetail = lazy(() => import('../Views/ViewProgrammazioniList/partials/ProgrammazioneDetail'));
const AddNewProgrammazione = lazy(() => import('../Views/ViewProgrammazioniList/partials/AddNewProgrammazione'));
const ViewRepSchemeList = lazy(() => import('../Views/ViewRepSchemeList/index'));
const AddRepScheme = lazy(() => import ('../Views/ViewRepSchemeList/partials/AddNewRepScheme'));
const EditRepScheme = lazy(() => import("../Views/ViewRepSchemeList/partials/EditRepScheme"));
const ViewRepSchemeTypeList = lazy(() => import("../Views/ViewRepSchemeTypeList"));
const AddNewRepSchemeType = lazy(() => import ('../Views/ViewRepSchemeTypeList/partials/AddNewRepSchemeType'));
const EditRepSchemeType = lazy(() => import ('../Views/ViewRepSchemeTypeList/partials/EditRepSchemeType'));
const ViewEffortList = lazy(() => import("../Views/ViewEffortList"));
const AddNewEffort = lazy(() => import ('../Views/ViewEffortList/partials/AddNewEffort'));
const EditEffort = lazy(() => import ('../Views/ViewEffortList/partials/EditEffort'));
const EditProgrammazione = lazy(() => import('../Views/ViewProgrammazioniList/partials/EditProgrammazione'));
const NewUser = lazy(() => import('../Views/ViewUsersList/partials/NewUser'));
const ViewVerifyUser = lazy(() => import('../Views/ViewVerifyUser/index'));
const ViewReIssuePassword = lazy(() => import('../Views/ViewReIssuePassword/index'));
const ViewEffortTypeList = lazy(() => import('../Views/ViewEffortTypeList/index'));
const AddNewEffortType = lazy(() => import('../Views/ViewEffortTypeList/partials/AddNewEffortType'));
const EditEffortType = lazy(() => import('../Views/ViewEffortTypeList/partials/EditEffortType'));
const ViewChatList = lazy(() => import('../Views/ViewChatList/index'));
const ChatDetail = lazy(() => import('../Views/ViewChatList/partials/ChatDetail'));

const PrivateRoute = () => {
    let auth = useAuth();

    if (auth.user)
        return <Outlet />;

    return <Navigate to={ '/login' } />
};

const AdminRoute = () => {
    let auth = useAuth();

    if (auth.user && auth.user.role === Roles.Superadmin)
        return <Outlet />;

    return <Navigate to={ '/dashboard' } />
}

const TrainerRoute = () => {
    let auth = useAuth();

    if (auth.user && (auth.user.role === Roles.Superadmin || auth.user.role === Roles.Trainer))
        return <Outlet />;

    return <Navigate to={ '/dashboard' } />
}

const GuestRoute = () => {

    let auth = useAuth();

    if (auth.user) return <Navigate to={ '/' } />

    return <Outlet />;
}

const router = createBrowserRouter([
    {
        path: '',
        Component: Root,
        children: [
            {
                path: '',
                Component: PrivateRoute,
                children: [
                    {
                        path: 'dashboard',
                        Component: ViewDashboard,
                        children: []
                    },
                    {
                        path: 'programmazioni',
                        Component: ViewProgrammazioniList,
                        children: []
                    },
                    {
                        path: 'programmazioni/dettaglio/:id',
                        Component: ProgrammazioneDetail,
                        children: []
                    },
                    {
                        path: 'user/my-account',
                        Component: MyUserData,
                        children: []
                    },
                    {
                        path: 'chat',
                        Component: ViewChatList,
                        children: []
                    },
                    {
                        path: 'chat/:id',
                        Component: ChatDetail,
                        children: []
                    },
                    {
                        path: '',
                        Component: TrainerRoute,
                        children: [
                            {
                                path: 'programmazioni/new',
                                Component: AddNewProgrammazione,
                                children: []
                            },
                            {
                                path: 'programmazioni/edit/:id',
                                Component: EditProgrammazione,
                                children: []
                            },
                            {
                                path: 'user/create',
                                Component: NewUser,
                                children: []
                            },
                            {
                                path: 'lista-utenti',
                                Component: ViewUsersList,
                                children: []
                            },
                            {
                                path: 'user/data/:id',
                                Component: ViewUserData
                            },
                            {
                                path: 'pattern-motori',
                                Component: ViewMotorPatternList,
                                children: []
                            },
                            {
                                path: 'pattern-motori/new',
                                Component: AddNewMotorPattern,
                                children: []
                            },
                            {
                                path: 'pattern-motori/modifica',
                                Component: EditMotorPattern,
                                children: []
                            },
                            {
                                path: 'gruppi-muscolari',
                                Component: ViewMuscleGroupList,
                                children: []
                            },
                            {
                                path: 'gruppi-muscolari/new',
                                Component: AddNewMuscleGroup,
                                children: []
                            },
                            {
                                path: 'gruppi-muscolari/modifica',
                                Component: EditMuscleGroup,
                                children: []
                            },
                            {
                                path: 'esercizi',
                                Component: ViewExerciseList,
                                children: []
                            },
                            {
                                path: 'esercizi/new',
                                Component: AddNewExercise,
                                children: []
                            },
                            {
                                path: 'esercizi/:id/modifica',
                                Component: EditExercise,
                                children: []
                            },
                            {
                                path: 'varianti',
                                Component: ViewVariantList,
                                children: []
                            },
                            {
                                path: 'varianti/new',
                                Component: AddNewVariant,
                                children: []
                            },
                            {
                                path: 'varianti/modifica',
                                Component: EditVariant,
                                children: []
                            },
                            {
                                path: 'tecniche',
                                Component: ViewTecnicaList,
                                children: []
                            },
                            {
                                path: 'tecniche/new',
                                Component: AddNewTecnica,
                                children: []
                            },
                            {
                                path: 'tecniche/modifica',
                                Component: EditTecnica,
                                children: []
                            },
                            {
                                path: 'time-under-tension',
                                Component: ViewTUTList,
                                children: []
                            },
                            {
                                path: 'time-under-tension/new',
                                Component: AddNewTUT,
                                children: []
                            },
                            {
                                path: 'time-under-tension/modifica',
                                Component: EditTUT,
                                children: []
                            },
                            {
                                path: 'recupero',
                                Component: ViewRecList,
                                children: []
                            },
                            {
                                path: 'recupero/new',
                                Component: AddNewRec,
                                children: []
                            },
                            {
                                path: 'recupero/modifica',
                                Component: EditRec,
                                children: []
                            },
                            {
                                path: 'rep-scheme',
                                Component: ViewRepSchemeList,
                                children: []
                            },
                            {
                                path: 'rep-scheme/new',
                                Component: AddRepScheme,
                                children: []
                            },
                            {
                                path: 'rep-scheme/modifica',
                                Component: EditRepScheme,
                                children: []
                            },
                            {
                                path: 'rep-scheme-type',
                                Component: ViewRepSchemeTypeList,
                                children: []
                            },
                            {
                                path: 'rep-scheme-type/new',
                                Component: AddNewRepSchemeType,
                                children: []
                            },
                            {
                                path: 'rep-scheme-type/modifica',
                                Component: EditRepSchemeType,
                                children: []
                            },
                            {
                                path: 'effort',
                                Component: ViewEffortList,
                                children: []
                            },
                            {
                                path: 'effort/new',
                                Component: AddNewEffort,
                                children: []
                            },
                            {
                                path: 'effort/modifica',
                                Component: EditEffort,
                                children: []
                            },
                            {
                                path: 'effort-type',
                                Component: ViewEffortTypeList,
                                children: []
                            },
                            {
                                path: 'effort-type/new',
                                Component: AddNewEffortType,
                                children: []
                            },
                            {
                                path: 'effort-type/modifica',
                                Component: EditEffortType,
                                children: []
                            },
                        ]
                    },
                ],
            },
        ],
    },
    {
        path: 'verify/:token',
        Component: ViewVerifyUser,
        children: []
    },
    {
        path: 'verify/re-issue-password/:token',
        Component: ViewReIssuePassword,
        children: []
    },
    {
        path: '/404',
        Component: ViewNotFound
    },
    {
        path: '*',
        Component: () => <Navigate to={ '/404' } />
    },
    {
        path: 'public', Component: GuestRoute, children: []
    },
    {
        path: 'login',
        Component: Login
    },
]);

export default router;
