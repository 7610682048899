import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../../api/api.service";
import {Sesso} from "../../enums/Sesso";
import {Roles} from "../../enums/Roles";
import {User} from "../../types/User";


export type UsersState = {
    list: Array<{
        id: number;
        username: string;
        email: string;
        password: string;
        nome: string;
        cognome: string;
        sesso: Sesso;
        birthDate: string;
        peso?: number;
        altezza?: number;
        role?: Roles;
        createdAt?: string;
        updatedAt?: string;
        deletedAt?: string;
    }>,
    selectedUser: User | undefined,
    totalPages?: number | undefined,
};

const initialState: UsersState = {
    list: [],
    selectedUser: undefined,
    totalPages: undefined
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetUsersList: () => initialState,
        setUsersList: (state, action: PayloadAction<User[]>) => {
            state.list = action.payload;
        },
        setSelectedUser: (state, action: PayloadAction<User>) => {
            state.selectedUser = action.payload;
        },
        resetSelectedUser: (state) => {
            state.selectedUser = initialState.selectedUser;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(api.endpoints.getClients.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getClients.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.list = action.payload.list;
        });
    }
});

export const { resetUsersList, setUsersList, setSelectedUser, resetSelectedUser } = usersSlice.actions;

export default usersSlice.reducer;
