import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    CreateAnamnesiWeekQuery,
    CreateAnamnesiWeekQueryVariables,
    CreateChatQuery,
    CreateChatQueryVariables,
    CreateEffortQuery,
    CreateEffortQueryVariables, CreateEffortTypeQuery, CreateEffortTypeQueryVariables,
    CreateExerciseQuery,
    CreateMotorPatternQuery,
    CreateMotorPatternQueryVariables,
    CreateMuscleGroupQuery,
    CreateMuscleGroupQueryVariables,
    CreateProgrammazioneQuery,
    CreateRecQuery,
    CreateRecQueryVariables,
    CreateRepSchemeQuery,
    CreateRepSchemeQueryVariables,
    CreateRepSchemeTypeQuery,
    CreateRepSchemeTypeQueryVariables,
    CreateTecnicaQuery,
    CreateTecnicaQueryVariables,
    CreateTUTQuery,
    CreateTUTQueryVariables,
    CreateUserQuery,
    CreateUserQueryVariables,
    CreateVariantQuery,
    CreateVariantQueryVariables,
    DeleteAnamnesiWeekQuery,
    DeleteConversationQuery,
    DeleteEffortQuery, DeleteEffortTypeQuery,
    DeleteExerciseQuery,
    DeleteExerciseQueryVariables,
    DeleteMotorPatternQuery,
    DeleteMuscleGroupQuery,
    DeleteProgrammazioneQuery,
    DeleteRecQuery,
    DeleteRepSchemeQuery,
    DeleteRepSchemeTypeQuery,
    DeleteTecnicaQuery,
    DeleteTUTQuery,
    DeleteVariantQuery,
    ExportProgrammazioneQuery,
    ExportProgrammazioneQueryVariables,
    GetAnamnesiWeekQuery,
    GetAnamnesiWeekQueryVariables,
    GetChatQueryVariables,
    GetCirconferenzeQuery,
    GetCirconferenzeQueryVariables,
    GetClientsQuery,
    GetClientsQueryVariables,
    GetConversationQuery,
    GetConversationQueryVariables,
    GetEffortQuery,
    GetEffortQueryVariables, GetEffortTypeQuery, GetEffortTypeQueryVariables,
    GetExerciseListQueryVariables,
    GetExerciseQuery,
    GetExerciseQueryVariables,
    GetExercisesListQuery, GetImageByPathQuery, GetImageByPathQueryVariables,
    GetMotorPatternQuery,
    GetMotorPatternQueryVariables,
    GetMuscleGroupQuery,
    GetMuscleGroupQueryVariables,
    GetPlicheQuery,
    GetPlicheQueryVariables,
    GetProgrammazioneQuery,
    GetProgrammazioneQueryVariables,
    GetProgrammazioneWeeksQueryVariables,
    GetRecQuery,
    GetRecQueryVariables,
    GetRepSchemeQuery,
    GetRepSchemeQueryVariables,
    GetRepSchemeTypeQuery,
    GetRepSchemeTypeQueryVariables,
    GetRolesQuery,
    GetSingleProgrammazioneWeeksQuery,
    GetTecnicaQuery,
    GetTecnicaQueryVariables,
    GetTUTQuery,
    GetTUTQueryVariables,
    GetUnreadChatsQuery,
    GetUserByTokenQuery,
    GetUserByTokenQueryVariables,
    GetUserByUserIdQuery,
    GetUserByUserIdQueryVariables,
    GetUserSchedaQuery,
    GetUserSchedaQueryVariables,
    GetVariantQuery,
    GetVariantQueryVariables,
    InsertConversationQuery,
    InsertConversationQueryVariables,
    ListAnamnesiWeekQuery,
    ListAnamnesiWeekQueryVariables,
    ListChatQuery,
    ListChatQueryVariables,
    ListConversationByUserIdQueryVariables,
    ListConversationQuery,
    ListEffortQuery,
    ListEffortQueryVariables,
    ListEffortTypeQuery,
    ListMotorPatternQuery,
    ListMotorPatternQueryVariables,
    ListMuscleGroupQuery,
    ListMuscleGroupQueryVariables,
    ListProgrammazioneQuery,
    ListProgrammazioneQueryVariables,
    ListRecQuery,
    ListRepSchemeQuery, ListRepSchemeQueryVariables,
    ListRepSchemeTypeQuery,
    ListTecnicaQuery,
    ListTecnicaQueryVariables,
    ListTUTQuery,
    ListVariantQuery,
    ListVariantQueryVariables,
    MarkChatAsReadQuery,
    ReIssuePasswordQuery,
    ReIssuePasswordQueryVariables,
    UpdateAnamnesiWeekQuery,
    UpdateAnamnesiWeekQueryVariables,
    UpdateCirconferenzeQuery,
    UpdateCirconferenzeQueryVariables,
    UpdateEffortQuery,
    UpdateEffortQueryVariables,
    UpdateEffortTypeQuery,
    UpdateEffortTypeQueryVariables,
    UpdateExerciseQuery,
    UpdateExerciseQueryVariables,
    UpdateMotorPatternQuery,
    UpdateMotorPatternQueryVariables,
    UpdateMuscleGroupQuery,
    UpdateMuscleGroupQueryVariables,
    UpdatePlicheQuery,
    UpdatePlicheQueryVariables,
    UpdateProgrammazioneQuery,
    UpdateRecQuery,
    UpdateRecQueryVariables,
    UpdateRepSchemeQuery,
    UpdateRepSchemeQueryVariables,
    UpdateRepSchemeTypeQuery,
    UpdateRepSchemeTypeQueryVariables,
    UpdateTecnicaQuery,
    UpdateTecnicaQueryVariables,
    UpdateTUTQuery,
    UpdateTUTQueryVariables,
    UpdateUserQuery,
    UpdateUserQueryVariables,
    UpdateUserSchedaQuery,
    UpdateUserSchedaQueryVariables,
    UpdateVariantQuery,
    UpdateVariantQueryVariables,
    VerifyUserQuery,
    VerifyUserQueryVariables,
} from "../declarations/types";
import axios from "axios";
import {getEndpoint} from "../utils/stringUtil";
import { Storage } from "@capacitor/storage";

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: '' }),
    endpoints: build => {
        return ({
            getClients: build.query<GetClientsQuery, GetClientsQueryVariables>({
                async queryFn(variables: GetClientsQueryVariables) {
                    try {

                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetClientsQuery>(`${getEndpoint()}api/users/list`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getClientsChat: build.query<GetClientsQuery, GetClientsQueryVariables>({
                async queryFn(variables: GetClientsQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetClientsQuery>(`${getEndpoint()}api/users/chat/list`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getRoles: build.query<GetRolesQuery, {}>({
                async queryFn() {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetRolesQuery>(`${getEndpoint()}api/roles/list`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getUserByToken: build.query<GetUserByTokenQuery, GetUserByTokenQueryVariables>({
                async queryFn(variables: GetUserByTokenQueryVariables) {
                    try {

                        const response = (
                            await axios.get<GetUserByTokenQuery>(`${getEndpoint()}api/verify/${ variables.isReIssue ? 're-issue-password' : 'user' }`, {
                                params: variables,
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            verifyUser: build.query<VerifyUserQuery, VerifyUserQueryVariables>({
                async queryFn(variables: VerifyUserQueryVariables) {
                    try {

                        const response = (
                            await axios.post<VerifyUserQuery>(`${getEndpoint()}api/verify-user`, variables, {}));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            resetUserPassword: build.query<VerifyUserQuery, VerifyUserQueryVariables>({
                async queryFn(variables: VerifyUserQueryVariables) {
                    try {

                        const response = (
                            await axios.post<VerifyUserQuery>(`${getEndpoint()}api/reset-password`, variables, {}));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertUser: build.query<CreateUserQuery, CreateUserQueryVariables>({
                async queryFn(variables: CreateUserQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.post<CreateUserQuery>(`${getEndpoint()}api/users`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getUserByUserId: build.query<GetUserByUserIdQuery, GetUserByUserIdQueryVariables>({
                async queryFn(variables: GetUserByUserIdQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetUserByUserIdQuery>(`${getEndpoint()}api/user/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            reIssueTemporaryPassword: build.query<ReIssuePasswordQuery, ReIssuePasswordQueryVariables>({
                async queryFn(variables: ReIssuePasswordQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<ReIssuePasswordQuery>(`${getEndpoint()}api/re-issue-temporary-password/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getUserScheda: build.query<GetUserSchedaQuery, GetUserSchedaQueryVariables>({
                async queryFn(variables: GetUserSchedaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetUserSchedaQuery>(`${getEndpoint()}api/users/user-schedule/${variables.userId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editUserScheda: build.query<UpdateUserSchedaQuery, UpdateUserSchedaQueryVariables>({
                async queryFn(variables: UpdateUserSchedaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.put<UpdateUserSchedaQuery>(`${getEndpoint()}api/users/update-client-schedule/${variables.userId}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getCirconferenze: build.query<GetCirconferenzeQuery, GetCirconferenzeQueryVariables>({
                async queryFn(variables: GetCirconferenzeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetCirconferenzeQuery>(`${getEndpoint()}api/users/circonferenze/${variables.userId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editCirconferenze: build.query<UpdateCirconferenzeQuery, UpdateCirconferenzeQueryVariables>({
                async queryFn(variables: UpdateCirconferenzeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.put<UpdateCirconferenzeQuery>(`${getEndpoint()}api/users/update-circonferenze/${variables.userId}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getPliche: build.query<GetPlicheQuery, GetPlicheQueryVariables>({
                async queryFn(variables: GetPlicheQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetPlicheQuery>(`${getEndpoint()}api/users/pliche/${variables.userId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editPliche: build.query<UpdatePlicheQuery, UpdatePlicheQueryVariables>({
                async queryFn(variables: UpdatePlicheQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.put<UpdatePlicheQuery>(`${getEndpoint()}api/users/update-pliche/${variables.userId}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editUser: build.query<UpdateUserQuery, UpdateUserQueryVariables>({
                async queryFn(variables: UpdateUserQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.put<UpdateUserQuery>(`${getEndpoint()}api/user/${variables.userId}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Pattern Motori
            getMotorPatternList: build.query<ListMotorPatternQuery, ListMotorPatternQueryVariables>({
                async queryFn(variables: ListMotorPatternQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<ListMotorPatternQuery>(`${getEndpoint()}api/entities/motor-pattern/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getMotorPattern: build.query<GetMotorPatternQuery, GetMotorPatternQueryVariables>({
                async queryFn(variables: GetMotorPatternQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value

                        const response = (
                            await axios.get<GetMotorPatternQuery>(`${getEndpoint()}api/entities/motor-pattern/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertMotorPattern: build.query<CreateMotorPatternQuery, CreateMotorPatternQueryVariables>({
                async queryFn(variables: CreateMotorPatternQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateMotorPatternQuery>(`${getEndpoint()}api/entities/motor-pattern/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteMotorPattern: build.query<DeleteMotorPatternQuery, GetMotorPatternQueryVariables>({
                async queryFn(variables: GetMotorPatternQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteMotorPatternQuery>(`${getEndpoint()}api/entities/motor-pattern/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editMotorPattern: build.query<UpdateMotorPatternQuery, UpdateMotorPatternQueryVariables>({
                async queryFn(variables: UpdateMotorPatternQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateMotorPatternQuery>(
                                `${getEndpoint()}api/entities/motor-pattern/${variables.id}`,
                                {motorPattern: variables.motorPattern},
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Gruppi Muscolari
            getMuscleGroupList: build.query<ListMuscleGroupQuery, ListMuscleGroupQueryVariables>({
                async queryFn(variables: ListMuscleGroupQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListMuscleGroupQuery>(`${getEndpoint()}api/entities/muscle-group/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getMuscleGroup: build.query<GetMuscleGroupQuery, GetMuscleGroupQueryVariables>({
                async queryFn(variables: GetMuscleGroupQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetMuscleGroupQuery>(`${getEndpoint()}api/entities/muscle-group/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertMuscleGroup: build.query<CreateMuscleGroupQuery, CreateMuscleGroupQueryVariables>({
                async queryFn(variables: CreateMuscleGroupQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateMuscleGroupQuery>(`${getEndpoint()}api/entities/muscle-group/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteMuscleGroup: build.query<DeleteMuscleGroupQuery, GetMuscleGroupQueryVariables>({
                async queryFn(variables: GetMuscleGroupQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteMuscleGroupQuery>(`${getEndpoint()}api/entities/muscle-group/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editMuscleGroup: build.query<UpdateMuscleGroupQuery, UpdateMuscleGroupQueryVariables>({
                async queryFn(variables: UpdateMuscleGroupQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateMuscleGroupQuery>(
                                `${getEndpoint()}api/entities/muscle-group/${variables.id}`,
                                {muscleGroup: variables.muscleGroup},
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Esercizi
            getExercisesList: build.query<GetExercisesListQuery, GetExerciseListQueryVariables>({
                async queryFn(variables: GetExerciseListQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetExercisesListQuery>(`${getEndpoint()}api/entities/exercise/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            })
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getExercise: build.query<GetExerciseQuery, GetExerciseQueryVariables>({
                async queryFn(variables: GetExerciseQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetExerciseQuery>(`${getEndpoint()}api/entities/exercise/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertExercise: build.query<CreateExerciseQuery, FormData>({
                async queryFn(variables: FormData) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateExerciseQuery>(`${getEndpoint()}api/entities/exercise/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteExercise: build.query<DeleteExerciseQuery, DeleteExerciseQueryVariables>({
                async queryFn(variables: DeleteExerciseQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteExerciseQuery>(`${getEndpoint()}api/entities/exercise/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editExercise: build.query<UpdateExerciseQuery, UpdateExerciseQueryVariables>({
                async queryFn(variables: UpdateExerciseQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateExerciseQuery>(
                                `${getEndpoint()}api/entities/exercise/${variables.id}`,
                                variables.payload,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getImageByPath: build.query<GetImageByPathQuery, GetImageByPathQueryVariables>({
                async queryFn(variables: GetImageByPathQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<GetImageByPathQuery>(`${getEndpoint()}api/entities/exercise/get/from-path`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getFileByPath: build.query<GetImageByPathQuery, GetImageByPathQueryVariables>({
                async queryFn(variables: GetImageByPathQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<GetImageByPathQuery>(`${getEndpoint()}api/entities/files/get/from-path`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Varianti
            getVariantList: build.query<ListVariantQuery, ListVariantQueryVariables>({
                async queryFn(variables: ListVariantQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListVariantQuery>(`${getEndpoint()}api/entities/variant/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getVariant: build.query<GetVariantQuery, GetVariantQueryVariables>({
                async queryFn(variables: GetVariantQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetVariantQuery>(`${getEndpoint()}api/entities/variant/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertVariant: build.query<CreateVariantQuery, CreateVariantQueryVariables>({
                async queryFn(variables: CreateVariantQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateVariantQuery>(`${getEndpoint()}api/entities/variant/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteVariant: build.query<DeleteVariantQuery, GetVariantQueryVariables>({
                async queryFn(variables: GetVariantQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteVariantQuery>(`${getEndpoint()}api/entities/variant/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editVariant: build.query<UpdateVariantQuery, UpdateVariantQueryVariables>({
                async queryFn(variables: UpdateVariantQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateVariantQuery>(
                                `${getEndpoint()}api/entities/variant/${variables.id}`,
                                {exerciseRef: variables.exerciseRef, name: variables.name},
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Tecniche
            getTecnicheList: build.query<ListTecnicaQuery, ListTecnicaQueryVariables>({
                async queryFn(variables: ListTecnicaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListTecnicaQuery>(`${getEndpoint()}api/entities/tecnica/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getTecnica: build.query<GetTecnicaQuery, GetTecnicaQueryVariables>({
                async queryFn(variables: GetTecnicaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetTecnicaQuery>(`${getEndpoint()}api/entities/tecnica/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertTecnica: build.query<CreateTecnicaQuery, CreateTecnicaQueryVariables>({
                async queryFn(variables: CreateTecnicaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateTecnicaQuery>(`${getEndpoint()}api/entities/tecnica/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteTecnica: build.query<DeleteTecnicaQuery, GetTecnicaQueryVariables>({
                async queryFn(variables: GetTecnicaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteTecnicaQuery>(`${getEndpoint()}api/entities/tecnica/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editTecnica: build.query<UpdateTecnicaQuery, UpdateTecnicaQueryVariables>({
                async queryFn(variables: UpdateTecnicaQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateTecnicaQuery>(
                                `${getEndpoint()}api/entities/tecnica/${variables.id}`,
                                {name: variables.name},
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Time Under Tension
            getTUTList: build.query<ListTUTQuery, {}>({
                async queryFn() {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListTUTQuery>(`${getEndpoint()}api/entities/tut/get`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getTUT: build.query<GetTUTQuery, GetTUTQueryVariables>({
                async queryFn(variables: GetTUTQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetTUTQuery>(`${getEndpoint()}api/entities/tut/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertTUT: build.query<CreateTUTQuery, CreateTUTQueryVariables>({
                async queryFn(variables: CreateTUTQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateTUTQuery>(`${getEndpoint()}api/entities/tut/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteTUT: build.query<DeleteTUTQuery, GetTUTQueryVariables>({
                async queryFn(variables: GetTUTQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteTUTQuery>(`${getEndpoint()}api/entities/tut/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editTUT: build.query<UpdateTUTQuery, UpdateTUTQueryVariables>({
                async queryFn(variables: UpdateTUTQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateTUTQuery>(
                                `${getEndpoint()}api/entities/tut/${variables.id}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Recupero
            getRecList: build.query<ListRecQuery, {}>({
                async queryFn() {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListRecQuery>(`${getEndpoint()}api/entities/rec/get`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getRec: build.query<GetRecQuery, GetRecQueryVariables>({
                async queryFn(variables: GetRecQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetRecQuery>(`${getEndpoint()}api/entities/rec/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertRec: build.query<CreateRecQuery, CreateRecQueryVariables>({
                async queryFn(variables: CreateRecQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateRecQuery>(`${getEndpoint()}api/entities/rec/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteRec: build.query<DeleteRecQuery, GetRecQueryVariables>({
                async queryFn(variables: GetRecQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteRecQuery>(`${getEndpoint()}api/entities/rec/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editRec: build.query<UpdateRecQuery, UpdateRecQueryVariables>({
                async queryFn(variables: UpdateRecQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateRecQuery>(
                                `${getEndpoint()}api/entities/rec/${variables.id}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Programmazioni
            getProgrammazoniList: build.query<ListProgrammazioneQuery, ListProgrammazioneQueryVariables>({
                async queryFn(variables: ListProgrammazioneQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListProgrammazioneQuery>(`${getEndpoint()}api/programmazione/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getUserLastProgrammazione: build.query<GetProgrammazioneQuery, {}>({
                async queryFn() {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetProgrammazioneQuery>(`${getEndpoint()}api/programmazione/last-user-programmazione`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getProgrammazione: build.query<GetProgrammazioneQuery, GetProgrammazioneQueryVariables>({
                async queryFn(variables: GetProgrammazioneQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetProgrammazioneQuery>(`${getEndpoint()}api/programmazione/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertProgrammazione: build.query<CreateProgrammazioneQuery, FormData>({
                async queryFn(variables: FormData) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateProgrammazioneQuery>(`${getEndpoint()}api/programmazione/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteProgrammazione: build.query<DeleteProgrammazioneQuery, GetProgrammazioneQueryVariables>({
                async queryFn(variables: GetProgrammazioneQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteProgrammazioneQuery>(`${getEndpoint()}api/programmazione/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editProgrammazione: build.query<UpdateProgrammazioneQuery, { formData: FormData, id: number }>({
                async queryFn(variables: { formData: FormData, id: number }) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateProgrammazioneQuery>(
                                `${getEndpoint()}api/programmazione/${variables.id}`,
                                variables.formData,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            uploadAnamnesiFile: build.query<UpdateAnamnesiWeekQuery, { formData: FormData, id: number, fileName: string }>({
                async queryFn(variables: { formData: FormData, id: number, fileName: string }) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateAnamnesiWeekQuery>(
                                `${getEndpoint()}api/entities/anamnesi-week/${variables.id}/load-file/${variables.fileName}`,
                                variables.formData,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editProgrammazioneWeeks: build.query<UpdateProgrammazioneQuery, { weeks: string, programmazioneId: number }>({
                async queryFn(variables: { weeks: string, programmazioneId: number }) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateProgrammazioneQuery>(
                                `${getEndpoint()}api/programmazione-weeks/${variables.programmazioneId}`,
                                { weeks: variables.weeks },
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getSingleProgramazioneWeeks: build.query<GetSingleProgrammazioneWeeksQuery, GetProgrammazioneWeeksQueryVariables>({
                async queryFn(variables: GetProgrammazioneWeeksQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetSingleProgrammazioneWeeksQuery>(
                                `${getEndpoint()}api/programmazione-weeks/${variables.programmazioneId}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Rep Scheme
            getRepSchemeList: build.query<ListRepSchemeQuery, ListRepSchemeQueryVariables>({
                async queryFn(variables: ListRepSchemeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListRepSchemeQuery>(`${getEndpoint()}api/entities/rep-scheme/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getRepScheme: build.query<GetRepSchemeQuery, GetRepSchemeQueryVariables>({
                async queryFn(variables: GetRepSchemeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetRepSchemeQuery>(`${getEndpoint()}api/entities/rep-scheme/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertRepScheme: build.query<CreateRepSchemeQuery, CreateRepSchemeQueryVariables>({
                async queryFn(variables: CreateRepSchemeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateRepSchemeQuery>(`${getEndpoint()}api/entities/rep-scheme/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteRepScheme: build.query<DeleteRepSchemeQuery, GetRepSchemeQueryVariables>({
                async queryFn(variables: GetRepSchemeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteRepSchemeQuery>(`${getEndpoint()}api/entities/rep-scheme/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editRepScheme: build.query<UpdateRepSchemeQuery, UpdateRepSchemeQueryVariables>({
                async queryFn(variables: UpdateRepSchemeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateRepSchemeQuery>(
                                `${getEndpoint()}api/entities/rep-scheme/${variables.id}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Rep Scheme Type
            getRepSchemeTypeList: build.query<ListRepSchemeTypeQuery, {}>({
                async queryFn() {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListRepSchemeTypeQuery>(`${getEndpoint()}api/entities/rep-scheme-type/get`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getRepSchemeType: build.query<GetRepSchemeTypeQuery, GetRepSchemeTypeQueryVariables>({
                async queryFn(variables: GetRepSchemeTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetRepSchemeTypeQuery>(`${getEndpoint()}api/entities/rep-scheme-type/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertRepSchemeType: build.query<CreateRepSchemeTypeQuery, CreateRepSchemeTypeQueryVariables>({
                async queryFn(variables: CreateRepSchemeTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateRepSchemeTypeQuery>(`${getEndpoint()}api/entities/rep-scheme-type/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteRepSchemeType: build.query<DeleteRepSchemeTypeQuery, GetRepSchemeTypeQueryVariables>({
                async queryFn(variables: GetRepSchemeTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteRepSchemeTypeQuery>(`${getEndpoint()}api/entities/rep-scheme-type/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editRepSchemeType: build.query<UpdateRepSchemeTypeQuery, UpdateRepSchemeTypeQueryVariables>({
                async queryFn(variables: UpdateRepSchemeTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateRepSchemeTypeQuery>(
                                `${getEndpoint()}api/entities/rep-scheme-type/${variables.id}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Effort
            getEffortList: build.query<ListEffortQuery, ListEffortQueryVariables>({
                async queryFn(variables: ListEffortQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListEffortQuery>(`${getEndpoint()}api/entities/effort/get`, {
                                params: variables,
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getEffort: build.query<GetEffortQuery, GetEffortQueryVariables>({
                async queryFn(variables: GetEffortQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetEffortQuery>(`${getEndpoint()}api/entities/effort/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertEffort: build.query<CreateEffortQuery, CreateEffortQueryVariables>({
                async queryFn(variables: CreateEffortQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateEffortQuery>(`${getEndpoint()}api/entities/effort/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteEffort: build.query<DeleteEffortQuery, GetEffortQueryVariables>({
                async queryFn(variables: GetEffortQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteEffortQuery>(`${getEndpoint()}api/entities/effort/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editEffort: build.query<UpdateEffortQuery, UpdateEffortQueryVariables>({
                async queryFn(variables: UpdateEffortQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateEffortQuery>(
                                `${getEndpoint()}api/entities/effort/${variables.id}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Effort Type
            getEffortTypeList: build.query<ListEffortTypeQuery, {}>({
                async queryFn() {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListEffortTypeQuery>(`${getEndpoint()}api/entities/effort-type/get`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getEffortType: build.query<GetEffortTypeQuery, GetEffortTypeQueryVariables>({
                async queryFn(variables: GetEffortTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetEffortTypeQuery>(`${getEndpoint()}api/entities/effort-type/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertEffortType: build.query<CreateEffortTypeQuery, CreateEffortTypeQueryVariables>({
                async queryFn(variables: CreateEffortTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateEffortTypeQuery>(`${getEndpoint()}api/entities/effort-type/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteEffortType: build.query<DeleteEffortTypeQuery, GetEffortTypeQueryVariables>({
                async queryFn(variables: GetEffortTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteEffortTypeQuery>(`${getEndpoint()}api/entities/effort-type/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editEffortType: build.query<UpdateEffortTypeQuery, UpdateEffortTypeQueryVariables>({
                async queryFn(variables: UpdateEffortTypeQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateEffortTypeQuery>(
                                `${getEndpoint()}api/entities/effort-type/${variables.id}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            exportProgrammazione: build.query<ExportProgrammazioneQuery, ExportProgrammazioneQueryVariables>({
                async queryFn(variables: ExportProgrammazioneQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<ExportProgrammazioneQuery>(
                                `${getEndpoint()}api/programmazione/export`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Chat
            getConversationList: build.query<ListConversationQuery, ListConversationByUserIdQueryVariables>({
                async queryFn(variables: ListConversationByUserIdQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListConversationQuery>(`${getEndpoint()}api/conversations/user/${variables.userId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getUnreadChats: build.query<GetUnreadChatsQuery, ListConversationByUserIdQueryVariables>({
                async queryFn(variables: ListConversationByUserIdQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetUnreadChatsQuery>(`${getEndpoint()}api/chats/unread/${variables.userId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getConversation: build.query<GetConversationQuery, GetConversationQueryVariables>({
                async queryFn(variables: GetConversationQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetConversationQuery>(`${getEndpoint()}api/conversations/single/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            listChatsByConversation: build.query<ListChatQuery, ListChatQueryVariables>({
                async queryFn(variables: ListChatQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListChatQuery>(`${getEndpoint()}api/chats/conversation/${ variables.conversationId }`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertChat: build.query<CreateChatQuery, CreateChatQueryVariables>({
                async queryFn(variables: CreateChatQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateChatQuery>(`${getEndpoint()}api/chats/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertConversation: build.query<InsertConversationQuery, InsertConversationQueryVariables>({
                async queryFn(variables: InsertConversationQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<InsertConversationQuery>(`${getEndpoint()}api/conversations/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteConversation: build.query<DeleteConversationQuery, GetConversationQueryVariables>({
                async queryFn(variables: GetConversationQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteConversationQuery>(`${getEndpoint()}api/conversations/${variables.id}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            markChatAsRead: build.query<MarkChatAsReadQuery, GetChatQueryVariables>({
                async queryFn(variables: GetChatQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<MarkChatAsReadQuery>(
                                `${getEndpoint()}api/chats/${ variables.id }/read`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            //Anamnesi Week
            getAnamnesiWeekList: build.query<ListAnamnesiWeekQuery, ListAnamnesiWeekQueryVariables>({
                async queryFn(variables: ListAnamnesiWeekQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<ListAnamnesiWeekQuery>(`${getEndpoint()}api/entities/anamnesi-week/get`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            getAnamnesiWeek: build.query<GetAnamnesiWeekQuery, GetAnamnesiWeekQueryVariables>({
                async queryFn(variables: GetAnamnesiWeekQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.get<GetAnamnesiWeekQuery>(`${getEndpoint()}api/entities/anamnesi-week/single/${variables.programmazioneId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            insertAnamnesiWeek: build.query<CreateAnamnesiWeekQuery, CreateAnamnesiWeekQueryVariables>({
                async queryFn(variables: CreateAnamnesiWeekQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.post<CreateAnamnesiWeekQuery>(`${getEndpoint()}api/entities/anamnesi-week/create`, variables, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            deleteAnamnesiWeek: build.query<DeleteAnamnesiWeekQuery, GetAnamnesiWeekQueryVariables>({
                async queryFn(variables: GetAnamnesiWeekQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.delete<DeleteAnamnesiWeekQuery>(`${getEndpoint()}api/entities/anamnesi-week/${variables.programmazioneId}`, {
                                headers: {
                                    Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                    'x-refresh': JSON.parse(refreshToken || '').toString()
                                }
                            }));

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
            editAnamnesiWeek: build.query<UpdateAnamnesiWeekQuery, UpdateAnamnesiWeekQueryVariables>({
                async queryFn(variables: UpdateAnamnesiWeekQueryVariables) {
                    try {
                        let accessTokenPromise = await Storage.get({ key: 'Access-Token' });
                        let refreshTokenPromise = await Storage.get({ key: 'Refresh-Token' });

                        let accessToken = accessTokenPromise.value;
                        let refreshToken = refreshTokenPromise.value;

                        const response = (
                            await axios.put<UpdateAnamnesiWeekQuery>(
                                `${getEndpoint()}api/entities/anamnesi-week/${variables.programmazioneId}`,
                                variables,
                                {
                                    headers: {
                                        Authorization: `Bearer ${JSON.parse(accessToken || '').toString()}`,
                                        'x-refresh': JSON.parse(refreshToken || '').toString()
                                    }
                                }
                            )
                        );

                        if (response.data && response.status === 200) {
                            return response;
                        } else {
                            return handleError();
                        }
                    } catch (error: any) {
                        return {error};
                    }
                }
            }),
        });
    },
});

export const handleError = () => {
    throw new Error('Errore nel reperimento dei dati richiesti');
}

export const {
    useGetClientsQuery,
    useLazyEditUserQuery,
    useLazyGetClientsQuery,
    useGetClientsChatQuery,
    useLazyGetClientsChatQuery,
    useGetUserByUserIdQuery,
    useLazyGetUserByUserIdQuery,
    useLazyGetUserSchedaQuery,
    useGetRolesQuery,
    useLazyEditUserSchedaQuery,
    useLazyInsertUserQuery,
    useGetUserByTokenQuery,
    useLazyVerifyUserQuery,
    useLazyResetUserPasswordQuery,
    useLazyReIssueTemporaryPasswordQuery,
    useLazyGetCirconferenzeQuery,
    useLazyEditCirconferenzeQuery,
    useLazyGetPlicheQuery,
    useLazyEditPlicheQuery,

    useGetMotorPatternListQuery,
    useGetMotorPatternQuery,
    useLazyInsertMotorPatternQuery,
    useLazyDeleteMotorPatternQuery,
    useLazyEditMotorPatternQuery,
    useLazyGetMotorPatternQuery,

    useGetMuscleGroupListQuery,
    useGetMuscleGroupQuery,
    useLazyInsertMuscleGroupQuery,
    useLazyDeleteMuscleGroupQuery,
    useLazyEditMuscleGroupQuery,
    useLazyGetMuscleGroupQuery,

    useGetExercisesListQuery,
    useGetExerciseQuery,
    useLazyGetExerciseQuery,
    useLazyGetExercisesListQuery,
    useLazyInsertExerciseQuery,
    useLazyDeleteExerciseQuery,
    useLazyEditExerciseQuery,
    useLazyGetImageByPathQuery,
    useLazyGetFileByPathQuery,

    useGetVariantListQuery,
    useGetVariantQuery,
    useLazyInsertVariantQuery,
    useLazyDeleteVariantQuery,
    useLazyEditVariantQuery,
    useLazyGetVariantQuery,

    useGetTecnicheListQuery,
    useLazyGetTecnicheListQuery,
    useGetTecnicaQuery,
    useLazyInsertTecnicaQuery,
    useLazyDeleteTecnicaQuery,
    useLazyEditTecnicaQuery,
    useLazyGetTecnicaQuery,

    useGetTUTListQuery,
    useLazyGetTUTListQuery,
    useGetTUTQuery,
    useLazyInsertTUTQuery,
    useLazyDeleteTUTQuery,
    useLazyEditTUTQuery,
    useLazyGetTUTQuery,

    useGetRecListQuery,
    useLazyGetRecListQuery,
    useGetRecQuery,
    useLazyInsertRecQuery,
    useLazyDeleteRecQuery,
    useLazyEditRecQuery,
    useLazyGetRecQuery,

    useGetProgrammazoniListQuery,
    useLazyGetProgrammazoniListQuery,
    useGetUserLastProgrammazioneQuery,
    useLazyGetUserLastProgrammazioneQuery,
    useGetProgrammazioneQuery,
    useLazyGetProgrammazioneQuery,
    useLazyInsertProgrammazioneQuery,
    useLazyDeleteProgrammazioneQuery,
    useLazyEditProgrammazioneQuery,
    useLazyUploadAnamnesiFileQuery,
    useLazyEditProgrammazioneWeeksQuery,
    useGetSingleProgramazioneWeeksQuery,
    useLazyGetSingleProgramazioneWeeksQuery,

    useGetRepSchemeListQuery,
    useLazyGetRepSchemeListQuery,
    useGetRepSchemeQuery,
    useLazyInsertRepSchemeQuery,
    useLazyDeleteRepSchemeQuery,
    useLazyEditRepSchemeQuery,
    useLazyGetRepSchemeQuery,

    useGetRepSchemeTypeListQuery,
    useLazyGetRepSchemeTypeListQuery,
    useGetRepSchemeTypeQuery,
    useLazyInsertRepSchemeTypeQuery,
    useLazyDeleteRepSchemeTypeQuery,
    useLazyEditRepSchemeTypeQuery,
    useLazyGetRepSchemeTypeQuery,

    useGetEffortListQuery,
    useLazyGetEffortListQuery,
    useGetEffortQuery,
    useLazyInsertEffortQuery,
    useLazyDeleteEffortQuery,
    useLazyEditEffortQuery,
    useLazyGetEffortQuery,

    useGetEffortTypeListQuery,
    useGetEffortTypeQuery,
    useLazyInsertEffortTypeQuery,
    useLazyDeleteEffortTypeQuery,
    useLazyEditEffortTypeQuery,
    useLazyGetEffortTypeQuery,

    useGetAnamnesiWeekListQuery,
    useLazyGetAnamnesiWeekQuery,
    useGetAnamnesiWeekQuery,
    useLazyInsertAnamnesiWeekQuery,
    useLazyDeleteAnamnesiWeekQuery,
    useLazyEditAnamnesiWeekQuery,
    useLazyGetAnamnesiWeekListQuery,

    useLazyExportProgrammazioneQuery,

    useGetConversationListQuery,
    useLazyGetConversationListQuery,
    useLazyListChatsByConversationQuery,
    useListChatsByConversationQuery,
    useLazyDeleteConversationQuery,
    useLazyInsertChatQuery,
    useInsertChatQuery,
    useLazyInsertConversationQuery,
    useInsertConversationQuery,
    useGetConversationQuery,
    useLazyGetConversationQuery,
    useDeleteConversationQuery,
    useLazyMarkChatAsReadQuery,
    useMarkChatAsReadQuery,
    useGetUnreadChatsQuery,
    useLazyGetUnreadChatsQuery
} = api;
