import { createTheme } from "@mui/material";

const colors = {
    primary: '#24D07A',
    secondary: '#3697FF',
    warning: '#FFAB00',
    error: '#F35625',
    info: '#FFCE08',
    black: '#000',
    grey: '#f5f5f5',
    white: '#fff',
    success: '#28CA9E',
    primaryDark: '#3c3c34',
    _green: '#2affa3',
    _cyan: '#00ffff',
    red: '#9c1419'
};

export const mainGradient = {
    backgroundImage: `linear-gradient(to right, ${colors._green} 0%, ${colors._cyan} 100%)`
}

export const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    },
    palette: {
        background: {
          default: colors.primaryDark,
          paper: colors.primaryDark
        },
        primary: {
            main: colors.primary,
            dark: colors.primaryDark,
            light: colors.white,
        },
        secondary: {
            main: colors.secondary,
            light: colors.grey,
            dark: colors.red
        },
        warning: {
            main: colors.warning
        },
        error: {
            main: colors.error
        },
        info: {
            main: colors.info,
            light: colors.white
        },
        success: {
            main: colors.success
        },
        text: {
            primary: '#000000',
            secondary: '#999999'
        }
    },

    components: {
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    transform: 'translate(2px, -22px) scale(1) !important',
                    color: colors.primary
                },
            },
        },
    },
});

export const DRAWER_WIDTH = 256;
