import { useSnackbar } from "notistack";
import { FC, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { errorSelector } from "../redux/selectors/errorSelector";
import {useStyles} from "./styles";


export const CustomSnackbarProvider: FC = memo(() => {

    const error = useSelector(errorSelector);
    const { enqueueSnackbar } = useSnackbar();
    const styles = useStyles();

    useEffect(() => {
        if (error.message !== '' && error.variant !== 'default') {
            enqueueSnackbar({
                message: error.message,
                variant: error.variant,
                className: error.variant === 'error' ? styles.snackbarError : ''
            });
        }
    }, [error]);

    return <></>;
});

CustomSnackbarProvider.displayName = 'CustomSnackbarProvider';

export default CustomSnackbarProvider;
