import { configureStore } from "@reduxjs/toolkit";
import authReducer from './redux/slices/authSlice';
import {api} from "./api/api.service";
import {CurriedGetDefaultMiddleware} from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import usersListReducer from './redux/slices/usersSlice';
import entitiesReducer from './redux/slices/entitiesSlice';
import errorReducer from './redux/slices/errorSlice';
import programmazioneReducer from './redux/slices/programmazioneSlice';
import appReducer from './redux/slices/appSlice';
import chatReducer from './redux/slices/chatSlice';

const middleware = (getDefaultMiddlewares: CurriedGetDefaultMiddleware) =>
    getDefaultMiddlewares({ serializableCheck: false }).concat(
        [api.middleware],
    );

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        usersList: usersListReducer,
        entities: entitiesReducer,
        error: errorReducer,
        programmazioneList: programmazioneReducer,
        app: appReducer,
        chat: chatReducer
    },
    middleware
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
