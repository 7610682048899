import {createSlice} from "@reduxjs/toolkit";


export interface Legenda {
    legenda: {
        split: number,
        entity: string,
        value: string
    }[]
}

export type AppState = {
    drawerOpen: boolean;
    legenda: Legenda;
    selectedLegenda?: {
        split: number,
        value: string
    }
}

const initialState: AppState = {
    drawerOpen: false,
    legenda: {
        legenda: []
    },
    selectedLegenda: undefined
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleDrawer: (state) => {
            state.drawerOpen = !state.drawerOpen;
        },
        setLegenda: (state, action) => {
            state.legenda = action.payload;
        },
        resetLegenda: (state) => {
            state.legenda = initialState.legenda;
        },
        setSelectedLegenda: (state, action) => {
            state.selectedLegenda = action.payload;
        },
        resetSelectedLegenda: (state) => {
            state.selectedLegenda = initialState.selectedLegenda;
        }
    }
});

export const { toggleDrawer, setLegenda, resetLegenda, setSelectedLegenda, resetSelectedLegenda } = appSlice.actions;

export default appSlice.reducer;
