import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../../api/api.service";
import {MotorPattern} from "../../types/MotorPattern";
import {MuscleGroup} from "../../types/MuscleGroup";
import {Exercise} from "../../types/Exercise";
import {Variant} from "../../types/Variant";
import {Tecnica} from "../../types/Tecnica";
import {TimeUnderTension} from "../../types/TimeUnderTension";
import {Rec} from "../../types/Rec";
import {RepSchemeType} from "../../types/RepSchemeType";
import {Effort, EffortType} from "../../types/Effort";
import {RepScheme} from "../../types/RepScheme";

export type EntitiesState = {
    motorPattern: {
        list: Array<{
            id: number;
            motorPattern: string
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedPattern?: MotorPattern | undefined
    },
    muscleGroup: {
        list: Array<{
            id: number;
            muscleGroup: string;
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedGroup?: MuscleGroup | undefined
    },
    exercise: {
        list: Array<{
            id: number;
            name: string;
            imageUrl?: string;
            motorPattern?: number;
            muscleGroup?: number;
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedExercise?: Exercise | undefined
    },
    variant: {
        list: Array<{
           id: number,
           exerciseRef: number,
           name: string
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedVariant?: Variant | undefined
    },
    tecnica: {
        list: Array<{
            id: number,
            name: string
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedTecnica?: Tecnica | undefined
    },
    timeUnderTension: {
        list: Array<{
            id: number,
            ecc: number;
            stopEcc: number;
            conc: number;
            stopConc: number;
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedTUT?: TimeUnderTension | undefined
    },
    rec: {
        list: Array<{
            id: number,
            rec: number
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedRec?: Rec | undefined
    },
    repScheme: {
        list: Array<{
            id: number,
            repScheme: string,
            repSchemeType: number,
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedRepScheme?: RepScheme | undefined
    },
    repSchemeType: {
        list: Array<{
            id: number,
            repSchemeType: string
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedRepSchemeType?: RepSchemeType | undefined
    },
    effort: {
        list: Array<{
            id: number,
            effort: string,
            effortType: number
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedEffort?: Effort | undefined
    },
    effortType: {
        list: Array<{
            id: number,
            effortType: string
        }>,
        totalPages?: number | undefined,
        errors?: any | undefined,
        selectedEffortType?: EffortType | undefined
    }
}

const initialState: EntitiesState = {
    motorPattern: {
        list: [],
        errors: undefined,
        totalPages: undefined,
        selectedPattern: undefined
    },
    muscleGroup: {
        list: [],
        errors: undefined,
        totalPages: undefined,
        selectedGroup: undefined
    },
    exercise: {
        list: [],
        errors: undefined,
        totalPages: undefined,
        selectedExercise: undefined
    },
    variant: {
        list: [],
        errors: undefined,
        totalPages: undefined,
        selectedVariant: undefined
    },
    tecnica: {
        list: [],
        errors: undefined,
        totalPages: undefined,
        selectedTecnica: undefined
    },
    timeUnderTension: {
        list: [],
        errors: undefined,
        totalPages: undefined,
        selectedTUT: undefined
    },
    rec: {
        list: [],
        totalPages: undefined,
        errors: undefined,
        selectedRec: undefined
    },
    repScheme: {
        list: [],
        totalPages: undefined,
        errors: undefined,
        selectedRepScheme: undefined
    },
    repSchemeType: {
        list: [],
        totalPages: undefined,
        errors: undefined,
        selectedRepSchemeType: undefined
    },
    effort: {
        list: [],
        totalPages: undefined,
        errors: undefined,
        selectedEffort: undefined
    },
    effortType: {
        list: [],
        totalPages: undefined,
        errors: undefined,
        selectedEffortType: undefined
    }
}

export const entitiesSlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {
        //Pattern Motori
        resetMotorPatterns: (state) => {
            state.motorPattern.list = [];
            state.motorPattern.errors = undefined;
            state.motorPattern.totalPages = undefined;
            state.motorPattern.selectedPattern = undefined;
        },
        resetSelectedPattern: (state) => {
            state.motorPattern.selectedPattern = undefined;
        },
        setSelectedPattern: (state, action: PayloadAction<MotorPattern>) => {
            state.motorPattern.selectedPattern = action.payload;
        },
        //Gruppi muscolari
        resetMuscleGroups: (state) => {
            state.muscleGroup.list = [];
            state.muscleGroup.errors = undefined;
            state.muscleGroup.totalPages = undefined;
            state.muscleGroup.selectedGroup = undefined;
        },
        resetSelectedGroup: (state) => {
            state.muscleGroup.selectedGroup = undefined;
        },
        setSelectedGroup: (state, action: PayloadAction<MuscleGroup>) => {
            state.muscleGroup.selectedGroup = action.payload;
        },
        //Esercizi
        resetExercises: (state) => {
            state.exercise.list = [];
            state.exercise.errors = undefined;
            state.exercise.totalPages = undefined;
            state.exercise.selectedExercise = undefined;
        },
        resetSelectedExercise: (state) => {
            state.exercise.selectedExercise = undefined;
        },
        setSelectedExercise: (state, action: PayloadAction<Exercise>) => {
            state.exercise.selectedExercise = action.payload;
        },
        //Varianti
        resetVariants: (state) => {
            state.variant.list = [];
            state.variant.errors = undefined;
            state.variant.totalPages = undefined;
            state.variant.selectedVariant = undefined;
        },
        resetSelectedVariant: (state) => {
            state.variant.selectedVariant = undefined;
        },
        setSelectedVariant: (state, action: PayloadAction<Variant>) => {
            state.variant.selectedVariant = action.payload;
        },
        //Tecniche
        resetTecniche: (state) => {
            state.tecnica.list = [];
            state.tecnica.errors = undefined;
            state.tecnica.totalPages = undefined;
            state.tecnica.selectedTecnica = undefined;
        },
        resetSelectedTecnica: (state) => {
            state.tecnica.selectedTecnica = undefined;
        },
        setSelectedTecnica: (state, action: PayloadAction<Tecnica>) => {
            state.tecnica.selectedTecnica = action.payload;
        },
        //Time Under Tension
        resetTUT: (state) => {
            state.timeUnderTension.list = [];
            state.timeUnderTension.errors = undefined;
            state.timeUnderTension.totalPages = undefined;
            state.timeUnderTension.selectedTUT = undefined;
        },
        resetSelectedTUT: (state) => {
            state.timeUnderTension.selectedTUT = undefined;
        },
        setSelectedTUT: (state, action: PayloadAction<TimeUnderTension>) => {
            state.timeUnderTension.selectedTUT = action.payload;
        },
        //Recupero
        resetRec: (state) => {
            state.rec.list = [];
            state.rec.errors = undefined;
            state.rec.totalPages = undefined;
            state.rec.selectedRec = undefined;
        },
        resetSelectedRec: (state) => {
            state.rec.selectedRec = undefined;
        },
        setSelectedRec: (state, action: PayloadAction<Rec>) => {
            state.rec.selectedRec = action.payload;
        },
        resetSelectedRepScheme: (state) => {
            state.repScheme.selectedRepScheme = undefined;
        },
        setSelectedRepScheme: (state, action: PayloadAction<RepScheme>) => {
            state.repScheme.selectedRepScheme = action.payload;
        },
        resetSelectedRepSchemeType: (state) => {
            state.repSchemeType.selectedRepSchemeType = undefined;
        },
        setSelectedRepSchemeType: (state, action: PayloadAction<RepSchemeType>) => {
            state.repSchemeType.selectedRepSchemeType = action.payload;
        },
        resetSelectedEffort: (state) => {
            state.effort.selectedEffort = undefined;
        },
        setSelectedEffort: (state, action: PayloadAction<Effort>) => {
            state.effort.selectedEffort = action.payload;
        },
        resetSelectedEffortType: (state) => {
            state.effortType.selectedEffortType = undefined;
        },
        setSelectedEffortType: (state, action: PayloadAction<EffortType>) => {
            state.effortType.selectedEffortType = action.payload;
        }
    },
    extraReducers: builder => {
        //Pattern motori
        builder.addMatcher(api.endpoints.getMotorPatternList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getMotorPatternList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.motorPattern.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteMotorPattern.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteMotorPattern.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteMotorPattern.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.motorPattern.errors = action.payload;
        });
        //Gruppi muscolari
        builder.addMatcher(api.endpoints.getMuscleGroupList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getMuscleGroupList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.muscleGroup.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteMuscleGroup.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteMuscleGroup.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteMuscleGroup.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.muscleGroup.errors = action.payload;
        });
        //Esercizi
        builder.addMatcher(api.endpoints.getExercisesList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getExercisesList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.exercise.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.getExercise.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getExercise.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        //Varianti
        builder.addMatcher(api.endpoints.getVariantList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getVariantList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.variant.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteVariant.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteVariant.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteVariant.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.variant.errors = action.payload;
        });
        //Tecniche
        builder.addMatcher(api.endpoints.getTecnicheList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getTecnicheList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.tecnica.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteTecnica.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteTecnica.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteTecnica.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.tecnica.errors = action.payload;
        });
        //Time Under Tension
        builder.addMatcher(api.endpoints.getTUTList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getTUTList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.timeUnderTension.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteTUT.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteTUT.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteTUT.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.timeUnderTension.errors = action.payload;
        });
        //Recupero
        builder.addMatcher(api.endpoints.getRecList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getRecList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.rec.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteRec.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteRec.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteRec.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.rec.errors = action.payload;
        });
        //Rep Range
        builder.addMatcher(api.endpoints.getRepSchemeList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getRepSchemeList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.repScheme.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteRepScheme.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteRepScheme.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteRepScheme.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.repScheme.errors = action.payload;
        });
        //Rep Scheme Type
        builder.addMatcher(api.endpoints.getRepSchemeTypeList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getRepSchemeTypeList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.repSchemeType.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteRepSchemeType.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteRepSchemeType.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteRepSchemeType.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.repSchemeType.errors = action.payload;
        });
        //Effort
        builder.addMatcher(api.endpoints.getEffortList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getEffortList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.effort.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteEffort.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteEffort.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteEffort.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.effort.errors = action.payload;
        });
        //Effort Type
        builder.addMatcher(api.endpoints.getEffortTypeList.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.getEffortTypeList.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
            state.effortType.list = action.payload.list;
        });
        builder.addMatcher(api.endpoints.deleteEffortType.matchPending, (state, action) => {
            console.log('Pending: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteEffortType.matchFulfilled, (state, action) => {
            console.log('Fulfilled: ', state, action);
        });
        builder.addMatcher(api.endpoints.deleteEffortType.matchRejected, (state, action) => {
            console.log('Rejected', state, action);
            state.effortType.errors = action.payload;
        });
    }
});

export const {
    resetMotorPatterns,
    resetSelectedPattern,
    setSelectedPattern,
    resetMuscleGroups,
    setSelectedGroup,
    resetSelectedGroup,
    resetExercises,
    resetSelectedExercise,
    setSelectedExercise,
    resetVariants,
    resetSelectedVariant,
    setSelectedVariant,
    resetTecniche,
    resetSelectedTecnica,
    setSelectedTecnica,
    resetTUT,
    setSelectedTUT,
    resetSelectedTUT,
    resetRec,
    resetSelectedRec,
    setSelectedRec,
    setSelectedRepScheme,
    resetSelectedRepScheme,
    setSelectedRepSchemeType,
    resetSelectedRepSchemeType,
    setSelectedEffort,
    resetSelectedEffort,
    setSelectedEffortType,
    resetSelectedEffortType
} = entitiesSlice.actions;

export default entitiesSlice.reducer;
