import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AuthProvider from "./Providers/AuthProvider";
import {RouterProvider} from "react-router-dom";
import router from "./router/router";
import { Provider } from "react-redux";
import {store} from "./store";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/styles";
import { SnackbarProvider } from "notistack";
import {theme} from "./theme";
import CustomSnackbarProvider from "./Providers/CustomSnackbarProvider";
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/it';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { TailSpin } from 'react-loader-spinner';
import "react-chat-elements/dist/main.css"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={ store }>
          <AuthProvider>
              <SnackbarProvider style={{ marginBottom: 'env(safe-area-inset-bottom)' }} maxSnack={3}>
                  <ThemeProvider theme={ theme }>
                      <CssBaseline />
                      <Suspense 
                        fallback={
                            <div 
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '25%'
                                }}
                            >
                                <TailSpin
                                    visible={ true }
                                    height={ 80 }
                                    width={ 80 }
                                    color={ '#9c1419' }
                                    ariaLabel='Loading...'
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        }
                      >
                          <CustomSnackbarProvider />
                          <LocalizationProvider adapterLocale="it" dateAdapter={AdapterDayjs}>
                              <RouterProvider router={ router } />
                          </LocalizationProvider>
                      </Suspense>
                  </ThemeProvider>
              </SnackbarProvider>
          </AuthProvider>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
