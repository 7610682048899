export const stringNoSpace = (s: string): string => {
  return s.replace(' ', '-').toLowerCase();
};

export const formatStringWithDate = (s: string): string => {
    const date = new Date();
    date.setSeconds(0, 0);
    return s.concat(`_at-${date.toISOString()}`);
}

export const formatFile = (name: string, type: string) => {
    return name.concat(`.${ type.split('/')[1] }`);
}

export const isJsonString = (str: string)=> {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const getEndpoint = () => {
    return process.env.REACT_APP_CURRENT_ENV === 'prod' ? process.env.REACT_APP_REMOTE_ENDPOINT : process.env.REACT_APP_LOCAL_ENDPOINT;
}

export const getWSEndpoint = (): string => {
    return (process.env.REACT_APP_CURRENT_ENV === 'prod' ? process.env.REACT_APP_REMOTE_WEBSOCKET : process.env.REACT_APP_LOCAL_WEBSOCKET) || '';
}
