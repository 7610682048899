import axios from "axios";
import {LoginResponse, User} from "../types/User";
import {getEndpoint} from "../utils/stringUtil";
import { Storage } from '@capacitor/storage';

export const authAPISignIn = async ({ username, password }: { username: string, password: string }) => {
    try {
        let userData = await axios.post<LoginResponse>(getEndpoint() + 'api/user/login', { username, password });
        if (userData.status === 200 && userData.data)
            return {
                responseCode: userData.status,
                user: userData.data.user,
                error: null,
                accessToken: userData.data.accessToken,
                refreshToken: userData.data.refreshToken
            };
    } catch (e: any) {
        if (e) {
            return {
                responseCode: e.response.status,
                user: null,
                error: e.response.data,
                accessToken: null,
                refreshToken: null
            }
        }
    }
};

export const authAPISignOut = async () => {
    try {

        let accessTokenResult = await Storage.get({ key: 'Access-Token' });
        let refreshTokenResult = await Storage.get({ key: 'Refresh-Token' });

        let accessToken = accessTokenResult.value;
        let refreshToken = refreshTokenResult.value;

        if (!!accessToken && !!refreshToken) {
            let session = await axios.post(getEndpoint() + 'api/user/logout', { }, {
                headers: {
                    Authorization: `Bearer ${ JSON.parse(accessToken).toString() }`,
                    'x-refresh': JSON.parse(refreshToken).toString()
                },
            });
            return session.data;
        }
    } catch(e: any) {
        if (e) console.error(e as string);
    }
};

export const getCurrentUser = async () => {
    try {
        let userAuth: User | null = null;
        const user = await Storage.get({ key: 'User' });
        if (!!user.value) {
            let sessionUser = await JSON.parse(user.value);
            if (sessionUser) userAuth = sessionUser as User;
        }
        return userAuth;
    } catch (error) {
        if (error) return null;
    }
}
